<template>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show  active " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" style='height:100%;overflow-y:scroll;'>
    
  </div>
  <div class="tab-pane fade show" id="pills-watch" role="tabpanel" aria-labelledby="pills-watch-tab" style=''>
    <div id="map-rapper">
    <div id="map"></div>
    <div id="toast_addr" class="input-group">
      <input type="text" class="form-control" placeholder="您的商店地址" aria-label="您的商店地址" aria-describedby="button-addon2" >

      <input type="number" class="form-control w-20" placeholder="樓層" aria-label="樓層" aria-describedby="button-addon2" style="width:20%;max-width: 20%;">


      <button class="btn btn-warning" type="button" id="button-addon2">
        確定
      </button>


  </div>
  <!--  
    <div class="toast" role="alert" id="toast_addr" aria-live="assertive" aria-atomic="true">
    
  <div class="toast-body">
    Hello, world! This is a toast message.
    <div class="mt-2 pt-2 border-top">
      <button type="button" class="btn btn-primary btn-sm">Take action</button>
      <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="toast">Close</button>
    </div>
  </div>
</div>
-->
  </div>
  </div>
  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" style='background-image: linear-gradient(#e1eaf7, #e6ffd4);height:90vh;overflow-y:auto;'>
    <h3>profile 2</h3>
    <p>Some content in menu 2.</p>
  </div>
  <div id="contact" class="tab-pane fade">
    <h3>contact 2</h3>
    <p>Some content in menu 2.</p>
  </div>
</div>
<div align="center"  id="bottom-menu" style=" position: fixed; bottom: 0px;width:100%;background-color: #fff9ba;z-index: 100;padding:3px;border-top:1px #e6e6e6 solid;background-image: linear-gradient(#aec9f5, yellow);">
    
    <ul class="nav nav-pills" id="pills-tab" role="tablist" style='float:right;margin-bottom:0px;line-height:0.3rem;'>
      <li class="nav-item" role="presentation">
      <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" >
      <i class="bi bi-house" style='font-size:200%;'></i>
      <br>
      <div class='trans_ui' style='display:inline-block;font-size:60%;'>MainPage</div>
      </button>
      </li>
      <li class="nav-item" id='show_watch_videos' role="presentation" style=''>
      <button class="nav-link" id="pills-watch-tab" data-bs-toggle="pill" data-bs-target="#pills-watch" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" style=''>
      <i class="bi bi-layout-sidebar-reverse" style='font-size:200%;'></i>
      <br>
      <div class='trans_ui' style='display:inline-block;font-size:60%;'>Watching exchange</div>s
      </button>
      </li>
      <li class="nav-item" id='show_worbook' role="presentation" style='display:none;'>
      <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" style=''>
      <i class="bi bi-layout-sidebar-reverse" style='font-size:200%;'></i>
      <br>
      <div class='trans_ui' style='display:inline-block;font-size:60%;'>History</div>
      </button>
      </li>
      <li class="nav-item" role="presentation">
      <button class="nav-link " id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" >
      <i class="bi bi-person" style='font-size:200%;'></i>
      <br>
      <div class='trans_ui' style='display:inline-block;font-size:60%;'>Account</div>
      </button>
      </li>
    </ul>

  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import $ from 'jquery'

const loader = new Loader({
  apiKey: "AIzaSyDmtnnViYo8VoIVb5aSFQ60_h0wPCwNZDA",
  version: "weekly"
});
let map ; 
loader.load().then(async () => {
  const { Map } = await window.google.maps.importLibrary("maps");

  map = new Map(document.getElementById("map"), {
    center: { lat: 25.0287128, lng: 121.5517164 },
    zoom: 15,
  });
  /*
  var controlDiv = document.getElementById('toast_addr');
    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(controlDiv);
    */
  return map ; 
});

$( document ).ready(function() {
  var h=window.innerHeight ;
    //$('#map-rapper').css("position","relative") ;
    $('#map').css("height",h) ;
    $('#map').css("position","absolute") ;

    $('#toast_addr').css("padding","0") ;
    $('#toast_addr').css("z-index","9999999999") ;
    $('#toast_addr').css("position","absolute") ;
    $('#toast_addr').css("top","60px") ;
    $('#toast_addr').css("left","10%") ;
    $('#toast_addr').css("width","80%") ;
});

export default {
    name: 'nav_bottom',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#map {
  width:100%;
  height:800px
}
.carousel-control-next-icon{
                          position:absolute;
                          top:90px;
                      }
                      .carousel-control-prev-icon{
                          position:absolute;
                          top:90px;
                      }
                      .carousel-control-next, .carousel-control-prev {
                          bottom:200px;
                          opacity:0.9;
                      }
                      
                      /*
                      .gradient-text {
                          background: linear-gradient(to right, white 10%, #08ecfc 20%,#fa05ee 30%,#e9f50c 40%,#0d05ff 50%,#ff0b03 60%,#d6f700 70%,#676e40 80%,#fa9005 90%,yellow 100%);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                      }
                      */
                      .gradient-text {
                          color : white;
                      }
</style>
