<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css" />
  
  <div class = "container">
    <img class = "img-fluid" alt = "Vue logo" src = "./assets/logo.png" />
    <h1 class = "display-1 text-bold" id="ani2" > Powerd By</h1>
    <h1  class ="display-1 text-bold animate__animated"  id="h1-ani">Vue</h1>
    <!--<HelloWorld msg = "Welcome to Your Vue.js App" /> -->
   
    
  </div>

   <nav_bottom/>
</template>

<script>
import $ from 'jquery'
import nav_bottom from './components/nav_bottom.vue' 
setTimeout(function() {
    $("#h1-ani").addClass("animate__fadeOutLeft")
    $(".container").hide()
  }, 8000);
export default {
  name: 'App',
  components: {
	nav_bottom
  }
}
</script>

<style>
:root {
  --neon-text-light: rgba(255,255,255, .25);
  --neon-text-color: #04f;
  --neon-text-stroke: #4CF1FF;
}

body {
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*height: 900px;*/
  /*background-image: url("https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80");*/
	background-size: cover;
	background-position: bottom center;
  
}

.container {
  background-image: linear-gradient(rgb(17, 16, 17), #404714);
}

#h1-ani {
  animation: backInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 6s; /* don't forget to set a duration! */
}

#ani2 {
  animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
  font-family: 'Dela Gothic One', sans-serif;
	font-weight: 400;
  color: #FEFCFF;
  background: rgb(0,140,110);
	background: linear-gradient(135deg, rgba(0,140,110,1) 20%, rgba(180,164,136,1) 25%, rgba(239,191,173,1) 30%, rgba(239,191,174,1) 35%, rgba(238,157,131,1) 50%, rgba(209,55,2,1) 70%, rgba(163,59,137,1) 85%, rgba(7,88,155,1) 100%);
	/* background-clip 要在背景的後面 */
	-webkit-background-clip: text;
	color: transparent;
}

#h1-ani {
	position: relative;
	z-index: 2;
	width: 100%;
	margin: 0;
  margin-top: 10%;
	padding-bottom: 0.1em;
	color: #FEFCFF;
	font-size: 15vw;
	font-family: 'Jura', sans-serif;
	line-height: 1;
	text-shadow: 
		-0.2rem -0.2rem 1rem var(--neon-text-light),
		0.2rem 0.2rem 1rem var(--neon-text-light),
		0 0 2rem var(--neon-text-color),
		0 0 4rem var(--neon-text-color),
		0 0 6rem var(--neon-text-color),
		0 0 8rem var(--neon-text-color),
		0 0 10rem var(--neon-text-color);
}

#h1-ani::before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		display: block;
		z-index: -1;
		height: 180%;
		aspect-ratio: 1 / 1;
		border: 0.03em solid #fff;
		border-radius: 50%;
		box-shadow:
			0 0 1.2rem 0 var(--neon-text-light),
			inset 0 0 1.2rem 0 var(--neon-text-light),
			0 0 1.2rem 0 var(--neon-text-color),
			inset 0 0 1.2rem 0 var(--neon-text-color);
}

</style>
